/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

import React from "react"
import { ThemeProvider } from "./src/components/ThemeContext"

export const wrapRootElement = ({ element }) => (
    <ThemeProvider>{element}</ThemeProvider>
)

export const onClientEntry = () => {
    require('./src/components/ThemeContext.js');
    require('./src/components/layout.js');
    require('./src/components/LayoutStyles.js');
    require('./src/components/layout.module.css');
}
