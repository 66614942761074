import * as React from 'react'

import { profileImageBase, profileImageDarkMode, profileImageLightMode } from './layout.module.css'
import 'bootstrap/dist/css/bootstrap.min.css'

import { GatsbyImage } from 'gatsby-plugin-image'
import { useStaticQuery, graphql } from "gatsby"

import {
  ProfileMain,
  ProfileCard,
  ProfileRow,
  Logo,
  Titles,
  ContactButtons,
  Button,
  MainTitle,
  SubTitle,
  LocationText,
} from './ProfileStyles'

import { ThemeContext } from './ThemeContext'

const isBrowser = () => typeof window !== "undefined"
const SCROLL_LEVEL_CHECK = 20

export const Profile = () => {
  const [headerOffset, setHeaderOffset] = React.useState(0);

  React.useCallback(() => {
    setHeaderOffset(document.getElementById('header').offsetHeight);
  }, []);
  React.useEffect(() => {
    const onScroll = (e) => {
      let picObject = document.getElementsByTagName('picture');
      if (e.target.documentElement.scrollTop < SCROLL_LEVEL_CHECK) {
        if (headerOffset) document.getElementById('header').style.height = `${headerOffset}px`;

        document.getElementById('MainTitle').style.fontSize = '27px';
        document.getElementById('SubTitle').style.fontSize = '21px';
        document.getElementById('LocationText').style.fontSize = '17px';
        document.getElementById('ProfileCard').style.marginTop = '5px';

        if (picObject.length > 0) {
          picObject[0].parentElement.style.height = '100px';
          picObject[0].parentElement.style.width = '100px';
          picObject[0].parentElement.style.transition = '0.4s';
        }
      } else {
        if (headerOffset) document.getElementById('header').style.height = `${headerOffset - 100}px`;

        document.getElementById('MainTitle').style.fontSize = '19px';
        document.getElementById('SubTitle').style.fontSize = '15px';
        document.getElementById('LocationText').style.fontSize = '12px';
        document.getElementById('ProfileCard').style.marginTop = '0';

        if (picObject.length > 0) {
          picObject[0].parentElement.style.height = '60px';
          picObject[0].parentElement.style.width = '60px';
          picObject[0].parentElement.style.transition = '0.4s';
        }
      }
    };

    let supportsPassive = false;
    try {
      let opts = Object.defineProperty({}, 'passive', {
        get: function () {
          supportsPassive = true;
          return supportsPassive;
        },
      });
      isBrowser() && window.addEventListener('testPassive', null, opts);
      isBrowser() && window.removeEventListener('testPassive', null, opts);
    } catch (e) {}
    isBrowser() && window.addEventListener('scroll', onScroll, supportsPassive ? { passive: true } : false);

    return () => isBrowser() && window.removeEventListener('scroll', onScroll);
  }, [headerOffset]);

  const imgData = useStaticQuery(graphql`
  {
    imageOne: file(relativePath: { eq: "profile.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED, width: 100)
      }
    }
  }`)

  return (
    <ThemeContext.Consumer>
      {(theme) => (
        <ProfileMain>
          <ProfileRow>
            <ProfileCard id="ProfileCard" sm={8} md={6}>
              <Logo>
                <GatsbyImage
                      className={profileImageBase}
                      imgClassName={theme.name === 'dark' ? profileImageDarkMode : profileImageLightMode}
                      image={imgData.imageOne.childImageSharp.gatsbyImageData}
                      alt="Shay Lavi"
                    />
              </Logo>
              <Titles theme={theme}>
                <MainTitle id="MainTitle">Shay Lavi</MainTitle>
                <SubTitle id="SubTitle">Full-stack Developer, Software Engineer</SubTitle>
                <LocationText id="LocationText">Melbourne, Australia</LocationText>
              </Titles>
            </ProfileCard>
            <ContactButtons sm={4} md={6} id="ContactButtons_ul">
              <ul>
                <li>
                  <Button
                    onClick={() => isBrowser() && window.open('https://www.linkedin.com/in/shay-lavi/', '_blank')}
                    className="btn btn-primary"
                  >
                    LinkedIn
                  </Button>
                </li>
                <li>
                  <Button onClick={() => isBrowser() && window.open('mailto:Contact@ShayLavi.com')} className="btn btn-primary">
                    eMail
                  </Button>
                </li>
              </ul>
            </ContactButtons>
          </ProfileRow>
        </ProfileMain>
      )}
    </ThemeContext.Consumer>
  )
}

export default Profile
