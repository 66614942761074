import { Row, Col } from 'react-bootstrap';

import styled from 'styled-components';

export const ProfileRow = styled(Row)`
  justify-content: space-between;
  margin-bottom: 10px;
  @media (max-width: 600px) {
    justify-content: center;
  }
`;

export const ProfileMain = styled.div`
  transition: 0.4s;
`;
export const ProfileCard = styled.div`
  display: flex;
  width: auto;
  padding-right: 15px;
`;
export const Logo = styled.div`
  display: flex;
  width: fit-content;
  float: left;
  padding: 10px;
  cursor: pointer;
  align-self: center;
`;
export const Titles = styled.div`
  display: flex;
  cursor: crosshair;
  justify-content: center;
  flex-direction: column;
  padding: 10px;
  max-width: 300px;
  text-shadow: ${(props) => (props.theme.name === 'dark' ? '2px 2px 7px #000000' : '2px 2px 7px #888888')};
`;
export const ContactButtons = styled(Col)`
  transition: 0.4s;
  display: flex;
  text-align: right;
  align-self: center;
  min-height: 100%;
  justify-content: space-evenly;
  align-self: stretch;
  width: auto;
  @media (max-width: 600px) {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    min-height: auto;
    width: 100%;
  }
`;
export const Button = styled.button`
  min-width: 70px;
  font-size: 13px;
`;

export const MainTitle = styled.h1`
  transition: 0.4s;
  font-size: 27px;
  font-weight: 650;
  letter-spacing: 0;
  text-transform: none;
  word-wrap: break-word;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
`;
export const SubTitle = styled.div`
  transition: 0.4s;
  font-size: 21px;
  letter-spacing: 0;
  line-height: 1.25;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  padding-bottom: 3px;
`;
export const LocationText = styled.div`
  transition: 0.4s;
  font-size: 17px;
  letter-spacing: 0;
  line-height: 1.25;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  padding-bottom: 3px;
`;
