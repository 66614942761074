import React, { useState } from 'react';
// import { getSunset, getSunrise } from 'sunrise-sunset-js';

export const ThemeContext = React.createContext({
  name: 'light',
  updateTheme: () => {},
});

export const ThemeProvider = ({ children }) => {
  // const melbCoordinates = { x: -37.8136, y: 144.9631 };

  const todayEvening = new Date();
  todayEvening.setHours(18, 30);
  const todayMorning = new Date();
  todayMorning.setHours(6, 30);
  // const yesterdayEvening = new Date();
  // yesterdayEvening.setDate(todayEvening.getDate() - 1);
  // yesterdayEvening.setHours(18, 30);

  // new Date() < getSunset(melbCoordinates.x, melbCoordinates.y, new Date()) &&
  // new Date() > getSunrise(melbCoordinates.x, melbCoordinates.y, yesterday)
  const themeMode =
  (new Date() > todayMorning && new Date() < todayEvening)
      ? 'light'
      : (new Date() < todayMorning || new Date() >= todayEvening)
      ? 'dark' : 'dark';
  const [theme, setTheme] = useState(themeMode);

  return (
    <ThemeContext.Provider
      value={{
        name: theme,
        updateTheme: setTheme,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};
