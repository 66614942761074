import * as React from 'react';

import './layout.module.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container } from 'react-bootstrap';

import sunIcon from '../images/sun-icon.svg';
import moonIcon from '../images/moon-icon.svg';

import Profile from './profile';
import { ThemeContext } from './ThemeContext';
import {
   GlobalStyle,
   Header,
   ThemedLayout,
   LightSwitch,
   Spacer,
   Fader
} from './LayoutStyles';

const Layout = ({ children }) => {
  return (
    <ThemeContext.Consumer>
      {(theme) => (
        <ThemedLayout theme={theme}>
          <GlobalStyle theme={theme} />
          <Container fluid>
            <LightSwitch
              onChange={() => theme.updateTheme(theme.name === 'light' ? 'dark' : 'light')}
              id="ThemeSwitcher"
              checked={theme.name === 'dark'}
              onColor="#000000"
              offColor="#4565ff"
              checkedIcon={<img src={moonIcon} alt="moon icon" />}
              uncheckedIcon={<img src={sunIcon} alt="sun icon" />}
              boxShadow="0 0 2px 3px #B38CD9"
              activeBoxShadow="0 0 2px 3px #dfb3e6"
            />

            <Header id="header" className="justify-content-center">
              <Profile />
            </Header>
            <Fader theme={theme} />
            <main className="content">
              <Spacer />
              {children}
            </main>
          </Container>
        </ThemedLayout>
      )}
    </ThemeContext.Consumer>
  );
};

export default Layout
