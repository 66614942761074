import Switch from 'react-switch';
import styled from 'styled-components';
import { Row } from 'react-bootstrap';
import { createGlobalStyle } from 'styled-components';

const themes = {
  light: {
    foreground: 'inherit',
    background: 'inherit',
  },
  dark: {
    foreground: '#ffffff',
    background: '#222222',
  },
};

export const GlobalStyle = createGlobalStyle`
body{
  background: ${(props) => (props.theme.name === 'dark' ? '#222222' : '#e4e4e4')};
  font-size: 14px;
}

#techstack {
  list-style: none;
  padding: 0;
  margin: 0;
}

#techstack li {
  padding-left: 1rem;
  text-indent: -0.75rem;
  line-height: 1.75;
}

#techstack li::before{
  content: "💎 ";
}


#ContactButtons_ul ul {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: stretch;
  justify-content: space-evenly;
  width: 100%;
  margin: 0;
  padding: 0;
  align-items: center;
}
@media (max-width: 600px) {
  #ContactButtons_ul ul {
    flex-direction: row;
    padding: 0 !important;
  }
}

#ContactButtons_ul ul li {
  list-style: none;
  display: block;
  flex: 0 1 auto;
  list-style-type: none;
}

&::-webkit-scrollbar {
    width: 11px;
    background: ${(props) => (props.theme.name === 'dark' ? '#222222' : '#e4e4e4')};
    border-radius: 100px;
  }
  
  &::-webkit-scrollbar-track {
    background: ${(props) => (props.theme.name === 'dark' ? '#222222' : '#e4e4e4')};
    border-radius: 100px;
  }

  &::-webkit-scrollbar-thumb {
    -webkit-appearance: none;
    border-radius: 100px;
    border: ${(props) => (props.theme.name === 'dark' ? '6px solid rgba(255, 255, 255, 0.18)' : 'darkgrey')};
    border-left: 0;
    border-right: 0;
    box-shadow: inset 2px 2px 5px 0 rgba(#fff, 0.5);
    background: ${(props) => (props.theme.name === 'dark' ? 'rgba(98,98,98)' : 'darkgrey')};
  }
`;

export const Spacer = styled.div`
  top: 0;
  width: 100%;
  height: 230px;
`;

export const Fader = styled.div`
  display: flex;
  width: 100%;
  z-index: 5;
  background: ${(props) => props.theme.name === 'dark' ? 'rgb(41,41,41)' : 'background: rgb(255,255,255)'};
  background: ${(props) => props.theme.name === 'dark' ? 'linear-gradient(180deg, rgba(41,41,41,1) 70%, rgba(41,41,41,0) 100%)' : 'linear-gradient(180deg, rgba(255,255,255,1) 70%, rgba(255,255,255,0) 100%)'};
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  height: 140px;
  @media (max-width: 600px) {
    height: 230px;
  }
`;

export const Header = styled(Row)`
  z-index: 99;
  background: rgb(255, 255, 255);
  background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(209, 213, 216, 1) 22%);
  min-width: 100%;
  position: fixed;
  top: 0;
  transition: 0.65s;
`;
export const ThemedLayout = styled.div`
  transition: all 0.65s ease;

  & a {
    color: ${(props) => (props.theme.name === 'dark' ? '#B38CD9' : 'inherit')};
  }
  ${Header} {
    background: ${(props) => (props.theme.name === 'dark' ? 'rgb(34, 34, 34)' : 'rgb(255, 255, 255)')};
    background: ${(props) =>
      props.theme.name === 'dark'
        ? 'linear-gradient(0deg,rgba(34, 34, 34, 1) 0%,rgba(98, 98, 98, 0.99) 22%)'
        : 'linear-gradient(0deg,rgba(255, 255, 255, 1) 0%, rgba(209, 213, 216, 1) 22%)'};
  }

  background: linear-gradient(to top, ${themes['dark'].background} 50%, white 50%) bottom;
  background-size: 200% 200%;
  background-position: ${(props) => (props.theme.name === 'dark' ? 'bottom' : 'top')};

  color: ${(props) => themes[props.theme.name].foreground};
`;

export const LightSwitch = styled(Switch)`
  transform: rotate(90deg);
  width: min-content;
  position: fixed !important;
  right: 0;
  top: 30%;
  z-index: 100;
  @media (max-width: 600px) {
    top: 5%;
  }
`;
